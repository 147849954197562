<template>
  <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="row">
          <!-- top section -->
          <div class="col-12 mt-05">
              <!-- Big grid 4 -->
            <div class="row featured-4 mb-5">
              <!--Start big box news-->
              <div class="col-md-6 pt-05 pb-05 pe-md-05">
                <div class="card card-full text-white overflow zoom" v-for="publish in caricatur.slice(0,1)" :key="publish.id">
                  <div class="height-ratio image-wrapper">
                    <!--thumbnail-->
                    <a class="pointer container__img-holder">
                      <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                    </a>
                    <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-lg-shadow">
                      <a class="pointer">
                        <h2 class="h3 h2-sm h1-md display-4-lg fw-bold heading-letter-spacing text-white">{{ publish.title_ar.substring(0,70)+".." }}</h2>
                      </a>
                      <div class="news-meta d-none d-md-block">
                        <span class="news-author"> <a class="text-white fw-bold" > تاق برس</a></span>
                        <span class="news-date">{{ publish.upload_date.substring(0,10) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--End big box news-->
              <!--Start box news-->
              <div class="col-md-6 pt-05 pb-05 ps-md-05">
                <div class="row newsbox">
                  <!-- news box -->
                  <article class="col-6" v-for="publish in caricatur.slice(1,4)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom">
                      <div class="height-ratio image-wrapper">
                        <!--thumbnail-->
                        <a class="pointer container__img-holder">
                          <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                        </a>
                        <!-- category & title -->
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-shadow">
                          <a  class="pointer">
                            <h3 class="h5 h3-sm h5-md text-light my-1">{{ publish.title_ar.substring(0,70)+".." }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <!--End box news-->
            </div>
            <!--End Big grid 4-->
          </div>
           
          <aside class="col-md-4 end-sidebar-lg" style="width: 100%;" v-if="showMore">
            <div class="sticky">
              <!-- block start -->
              <aside class="block-area">
                <!--block title-->
                <div class="block-title-11">
                  <h4 class="h5 center-backline">
                    <span class="bg-themes">كاركاتير </span>
                  </h4>
                </div>
                <!-- block-content -->
                <div class="row">
                  <!--post start-->
                  <article class="col-6 col-md-12 col-lg-6" v-for="publish in caricatur.slice(4,8)" :key="publish.id">
                    <div class="card card-full hover-a mb-4">
                      <!--thumbnail-->
                      <div class="ratio_230-129 image-wrapper">
                        <a class="pointer container__img-holder">
                          <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                        </a>
                      </div>
                      <div class="card-body">
                        <!-- date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <time datetime="2019-10-21">{{ publish.upload_date.substring(0,10) }}</time>
                        </div>
                        <!--title-->
                        <h2 class="card-title h5 h4-sm h5-md h6-lg"><a >{{ publish.title_ar.substring(0,70)+".." }}</a></h2>
                      </div>
                    </div>
                  </article>
                  <!--post start-->
                </div>
                <!-- end block content -->
              </aside>
              <!-- end block -->
            </div>
          </aside>
          
          <!--start eft column-->
          <div class="col-md-8" v-if="showMore">
            <div class="block-area">
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <!-- <span class="bg-primary text-white"></span> -->
                </h4>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(8,9)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a class="pointer container__img-holder">
                        <img class="img-fluid myImg" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg">
                        <a >{{ publish.title_ar.substring(0,100) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 pointer">
                          <a class="fw-bold" href="">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                  <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(9,10)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a class="pointer container__img-holder">
                        <img class="img-fluid myImg" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg">
                        <a >{{ publish.title_ar.substring(0,100) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 pointer">
                          <a class="fw-bold" href="">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post left-->
                <!--post right list start-->
                <div class="col-lg-6">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(10,16)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_115-80 image-wrapper">
                          <a class="pointer container__img-holder">
                            <img class="img-fluid myImg" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-lg pointer">
                            <a >{{ publish.title_ar.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                </div>
              </div>

            </div>
          </div>
          <aside class="col-md-4 end-sidebar-lg" v-if="showMore">
            <aside class="widget">
                <!--block title-->
                <div class="block-title-9">
                  <h4 class="h5">
                    <span class="border-primary bg-secondary text-white"></span>
                  </h4>
                </div>
                <!-- block content -->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(16,22)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a class="pointer container__img-holder">
                            <img class="img-fluid" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md pointer">
                            <a >{{ publish.title_ar.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                  
                </div>
                <!--end block content-->
                <div class="gap-0"></div>
              </aside>
          </aside>

                    <!--Block start-->
<div class="block-area" v-if="showMore">
  <!-- block title -->
  <div class="block-title-6">
    <h4 class="h5 border-primary">
      <!-- <span class="bg-primary text-white">Hot ]</span> -->
    </h4>
  </div>
  <div class="row">
    <!--left post-->
    <div class="col-lg-6">
      <!--post big start-->
      <div class="big-post">
        <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(22,23)" :key="publish.id">
          <!--thumbnail-->
          <div class="ratio_360-202 image-wrapper">
            <a class="pointer container__img-holder">
              <img class="img-fluid" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
            </a>
          </div>
          <!-- title & date -->
          <div class="position-absolute p-3 b-0 w-100 bg-shadow">
            <h4 class="card-title h2 h1-sm h3-lg">
              <a class="text-white">{{ publish.title_ar.substring(0,50) }}</a>
            </h4>
            <div class="card-text small text-light">
              <time datetime="2019-10-18">{{ publish.upload_date.substring(0,10) }}</time>
            </div>
          </div>
        </article>
      </div>
      <!--end post big-->
      <!--post small start-->
      <div class="small-post">
        <!--post list-->
        <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(23,26)" :key="publish.id">
          <div class="row">
            <!--thumbnail-->
            <div class="col-3 col-md-4 pe-2 pe-md-0">
              <div class="ratio_115-80 image-wrapper">
                <a class="pointer container__img-holder">
                  <img class="img-fluid" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                </a>
              </div>
            </div>
            <!-- title & date -->
            <div class="col-9 col-md-8">
              <div class="card-body pt-0">
                <h3 class="card-title h6 h5-sm h6-lg">
                  <a>{{ publish.title_ar.substring(0,50) }}</a>
                </h3>
                <div class="card-text small text-muted">
                  <time datetime="2019-10-16">{{ publish.upload_date.substring(0,10) }}</time>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <!--end post small-->
    </div>
    <!--end left post-->

    <!--right post-->
    <div class="col-lg-6">
      <!--post big start-->
      <div class="big-post">
        <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(26,27)" :key="publish.id">
          <!--thumbnail-->
          <div class="ratio_360-202 image-wrapper">
            <a class="pointer container__img-holder">
              <img class="img-fluid" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
            </a>
          </div>
          <!-- title & date -->
          <div class="position-absolute p-3 b-0 w-100 bg-shadow">
            <h4 class="card-title h2 h1-sm h3-lg">
              <a class="text-white">{{ publish.title_ar.substring(0,100) }}</a>
            </h4>
            <div class="card-text small text-light">
              <time datetime="2019-10-18">{{ publish.upload_date.substring(0,10) }}</time>
            </div>
          </div>
        </article>
      </div>
      <!--end post big-->
      <!--post small start-->
      <div class="small-post">
        <!--post list-->
        <article class="card card-full hover-a mb-4" v-for="publish in caricatur.slice(27,30)" :key="publish.id">
          <div class="row">
            <!--thumbnail-->
            <div class="col-3 col-md-4 pe-2 pe-md-0">
              <div class="ratio_115-80 image-wrapper">
                <a class="pointer container__img-holder">
                  <img class="img-fluid" v-lazy="publish.caricature" :alt="publish.title_ar.substring(0,70)">
                </a>
              </div>
            </div>
            <!-- title & date -->
            <div class="col-9 col-md-8">
              <div class="card-body pt-0">
                <h3 class="card-title h6 h5-sm h6-lg">
                  <a>{{ publish.title_ar.substring(0,100) }}</a>
                </h3>
                <div class="card-text small text-muted">
                  <time datetime="2019-10-09">{{ publish.upload_date.substring(0,10) }}</time>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <!--end post small-->
    </div>
    <!--end right post-->
  </div>
</div>
<!--End Block-->


          <!-- <div class="col-md-8" v-if="showMore"> -->
            <div class="col-md-8" v-if="showMore">
            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <div class="block-title-11">
                <h4 class="h5 center-backline">
                  <span class="bg-themes">مزيد من كاركاتير</span>
                </h4>
              </div>
              <!-- block-content -->
              <div class="row">
                <!--post start-->
                <article class="col-6 col-lg-4" v-for="publish in caricatur.slice(30,36)" :key="publish.id">
                  <div class="card card-full hover-a mb-4">
                    <!--thumbnail-->
                    <div class="ratio_230-129 image-wrapper">
                      <a class="pointer container__img-holder">
                        <img  class="img-fluid myImg" v-lazy="publish.caricature"  :alt="publish.title_ar.substring(0,70)">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- author, date & comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold" >تاق برس</a>
                        </span>
                        <time datetime="2019-10-21">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--title-->
                      <h2 class="card-title h5 h4-sm h6-md h5-lg"><a href="#">{{ publish.title_ar.substring(0,70)+".." }}</a></h2>
                    </div>
                  </div>
                </article>
                <!--post start-->
              </div>
              <!-- end block content -->
            </div>
            <!--End Block-->
          </div>
          
          <!-- <aside class="col-md-4 end-sidebar-lg" v-if="showMore"> -->
            <aside class="col-md-4 end-sidebar-lg" v-if="showMore">
            <div class="sticky">
              <!-- block start -->
              <aside class="block-area">
                <!--block title-->
                <div class="block-title-11">
                  <h4 class="h5 center-backline">
                    <span class="bg-themes">كاركاتير </span>
                  </h4>
                </div>
                <!-- block-content -->
                <div class="row">
                  <!--post start-->
                  <article class="col-6 col-md-12 col-lg-6" v-for="publish in caricatur.slice(36,40)" :key="publish.id">
                    <div class="card card-full hover-a mb-4">
                      <!--thumbnail-->
                      <div class="ratio_230-129 image-wrapper">
                        <a class="pointer container__img-holder">
                          <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                        </a>
                      </div>
                      <div class="card-body">
                        <!-- date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <time datetime="2019-10-21">{{ publish.upload_date.substring(0,10) }}</time>
                        </div>
                        <!--title-->
                        <h2 class="card-title h5 h4-sm h5-md h6-lg"><a >{{ publish.title_ar.substring(0,70)+".." }}</a></h2>
                      </div>
                    </div>
                  </article>
                  <!--post start-->
                </div>
                <!-- end block content -->
              </aside>
              <!-- end block -->
            </div>
          </aside>
          


      <div class="clearfix my-4" v-if="showMore">
        <nav class="float-start" aria-label="Page navigation example pointer">
         <paginate
           :page-count="2"
           :click-handler="clickCallback"
           :prev-text="'السابق'"
           :next-text="'التالي'"
           :container-class="'pagination'"
           :page-class="'page-item'">
        </paginate>
        </nav>
      </div>
          <div class="d-grid" v-else>
              <form action="http://sudanipay.com/subscription/tagpress/tagpress" method="POST">
                <button class="btn btn-primary" style="width: 100%;">مزيد من الكاركاتير</button>
              </form>
            </div>
        </div>
       
      </div>
      <div class="img-popup">
  <img src="" alt="Popup Image">
  <div class="close-btn">
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</div>
    </main>
    <br>
</template>

<script>
import $ from 'jquery'
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
import { watchEffect } from '@vue/runtime-core';
import Paginate from 'vuejs-paginate-next';
import { useMeta } from 'vue-meta'
export default {
   name: 'Caricatures',
   components: {
      paginate: Paginate,
    },
   async setup() {
    useMeta({ title: 'كاركاتير' })
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const caricatur = ref([]);
      const showMore = ref(true);

      try {
        await HTTP.get("getCaricature.php?LIMIT=40&OFFSET=0").then((res) => {
          caricatur.value = res.data.Caricature; 
        });
      } catch (err) {
        console.log(err);
      }

     const clickCallback = async (pageNum) => {
       if (pageNum == 1) {
        await HTTP.get(`getCaricature.php?LIMIT=40&OFFSET=0`).then(res => {
                caricatur.value = res.data.Caricature;
          })
       } else if (pageNum == 2) {
         await HTTP.get(`getCaricature.php?LIMIT=40&OFFSET=40`).then(res => {
                caricatur.value = res.data.Caricature;
          })
       } else if (pageNum == 3) {
         await HTTP.get(`getCaricature.php?LIMIT=40&OFFSET=80`).then(res => {
                caricatur.value = res.data.Caricature;
          })
       } else if (pageNum == 4) {
         await HTTP.get(`getCaricature.php?LIMIT=40&OFFSET=120`).then(res => {
                caricatur.value = res.data.Caricature;
          })
       } else if (pageNum == 5) {
         await HTTP.get(`getCaricature.php?LIMIT=40&OFFSET=160`).then(res => {
                caricatur.value = res.data.Caricature;
          })
       }
        console.log(pageNum)
      };

      watchEffect(() => {
        if (cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
           showMore.value =  true;
        } else {
           showMore.value = false;
        }
      })
      return {
        showMore,
        caricatur,
        clickCallback
      };
     
   },
    mounted() {

     $(document).ready(function() {

  // required elements
  var imgPopup = $('.img-popup');
  var imgCont  = $('.container__img-holder');
  var popupImage = $('.img-popup img');
  var closeBtn = $('.close-btn');

  // handle events
  imgCont.on('click', function() {
    var img_src = $(this).children('img').attr('src');
    imgPopup.children('img').attr('src', img_src);
    imgPopup.addClass('opened');
  });

  $(imgPopup, closeBtn).on('click', function() {
    imgPopup.removeClass('opened');
    imgPopup.children('img').attr('src', '');
  });

  popupImage.on('click', function(e) {
    e.stopPropagation();
  });
  
});

      
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

/* Popup Styling */
.img-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.img-popup img {
  max-width: 600px;
  width: 100%;
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
}

.close-btn {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.close-btn .bar {
  height: 4px;
  background: #fff;
}

.close-btn .bar:nth-child(1) {
  transform: rotate(45deg);
}

.close-btn .bar:nth-child(2) {
  transform: translateY(-4px) rotate(-45deg);
}

.opened {
  display: flex;
}

.opened img {
  animation: animatepopup 1s ease-in-out .8s;
  -webkit-animation: animatepopup .3s ease-in-out forwards;
}

@keyframes animatepopup {

  to {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

}

@media screen and (max-width: 880px) {

  .container .container__img-holder:nth-child(3n+1) {
    margin-left: 16px;
  }

}
</style>